import { stripRichTextWrapperTag } from '../../utils/html';

const getFormulas = (formulas, i18n) =>
  formulas.map(({ name, rest_share, april_share, third_party_share }) => ({
    title: name,
    shares: {
      rest: {
        value: rest_share === 0 ? 0.01 : rest_share,
        label: rest_share.toString() + i18n.t('global.price-unit'),
      },
      april: {
        value: april_share === 0 ? 0.01 : april_share,
        label: april_share.toString() + i18n.t('global.price-unit'),
      },
      thirdParty: {
        value: third_party_share === 0 ? 0.01 : third_party_share,
        label: third_party_share.toString() + i18n.t('global.price-unit'),
      },
    },
  }));

const getExamples = (examples, i18n) =>
  examples.map((example) => {
    const { title, description, formulas } = example.data_viz_example.data;

    const items = getFormulas(formulas, i18n);

    const maxTotal = items.reduce((value, formula) => {
      const { rest, april, thirdParty } = formula.shares;
      const formulaTotal = rest.value + april.value + thirdParty.value;
      if (formulaTotal > value) {
        return formulaTotal;
      }
      return value;
    }, 0);

    return {
      name: title,
      description: stripRichTextWrapperTag(description),
      graph: {
        shouldFormat: false,
        maxTotal,
        items,
      },
    };
  });

export default (
  { primary, items },
  data,
  { $i18n, $enhancedLinkSerializer }
) => {
  const {
    data_viz_title,
    data_viz_select_label,
    data_viz_legend_rest,
    data_viz_legend_april,
    data_viz_legend_third_party,
    data_viz_legal_mention,
    data_viz_primary_cta_link,
    data_viz_primary_cta_label,
    data_viz_secondary_cta_link,
    data_viz_secondary_cta_label,
  } = primary;

  const ctas = [];
  const primaryLink = $enhancedLinkSerializer(data_viz_primary_cta_link);
  const secondaryLink = $enhancedLinkSerializer(data_viz_secondary_cta_link);

  if (primaryLink && data_viz_primary_cta_label) {
    ctas.push({
      ...primaryLink,
      label: data_viz_primary_cta_label,
    });
  }

  if (secondaryLink && data_viz_secondary_cta_label) {
    ctas.push({
      ...secondaryLink,
      label: data_viz_secondary_cta_label,
      variant: 'secondary',
    });
  }

  return {
    title: stripRichTextWrapperTag(data_viz_title),
    legend: {
      rest: data_viz_legend_rest,
      april: data_viz_legend_april,
      thirdParty: data_viz_legend_third_party,
    },
    legalMention: data_viz_legal_mention,
    selectLabel: data_viz_select_label || $i18n.t('dataviz.select-label'),
    ctas: ctas.length && ctas,
    examples: getExamples(items, $i18n),
  };
};
